<template>
    <div class="home">
        <HeaderComponent />
        <FullContentGood />
        <FooterComponent />
    </div>
</template>

<script>
import HeaderComponent from '@/components/Header/Header.vue'
import FooterComponent from '@/components/Footer/Footer.vue'
import FullContentGood from '@/components/Catalog/Good/FullContentGood.vue'

export default {
  name: 'IndexPage',
  components: {
    HeaderComponent,
    FooterComponent,
    FullContentGood,
  },
  mounted () {
    window.scrollTo({
        top: 200,
        behavior: 'smooth'
    })
  }
}
</script>

<template>
    <td class="product">
        <img class="product-thumb" :src="mainImage" alt="">
        <div class="product-info">
            <h3 class="product-name">
                <router-link :to="{ name: 'good', params: { good_id:id}}">{{ name }}</router-link>
            </h3>
            <span class="product-price">{{ price }}</span>
        </div>
    </td>
    <td class="stock">
        <span v-if="balance > 0" class="in-stock">Остаток: {{ balance }} шт.</span>
        <span v-else class="out-of-stock">Нет в наличии</span>
    </td>
    <td class="product-subtotal">
        <button v-if="loading" class="add_to_cart_button">
            <div class="loader-button">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </button>
        <button 
            v-else
            type="submit" 
            class="add_to_cart_button"
            :class="{ 'as-disabled' : outOfStock }"
            @click="addToCart"
        >
        В корзину
        </button>
    </td>
    <td class="product-remove">
        <a @click="delFromWishlist" class="remove cursor-pointer"><i class="fa fa-close"></i></a>
    </td>
</template>

<script>
import PreviewImage from "@/assets/images/products/preview.png"
import {backendPath} from "@/main.js"
export default {
    name: 'WishlistItemComponent',
    props: ['goodInfo'],
    data () {
        return {
            PreviewImage,
            id: this.goodInfo.id,
            name: this.goodInfo.full_name,
            art: this.goodInfo.art,
            price: this.goodInfo.price,
            balance: Math.floor(this.goodInfo.balance),
            loading: false
        }
    },
    computed: {
        userToken () {
            return this.$store.getters.user_token
        },
        mainImage () {
            let path = ''
            if (this.goodInfo.images.length > 0) {
                path = backendPath + this.goodInfo.images[0].image.url
            }
            return path
        },
        qtyInCart () {
            let cartItemById = this.$store.getters.cartItemById(this.id)
            let qtyInCart = 0
            if (cartItemById) {
                qtyInCart = cartItemById.quantity
            }
            return qtyInCart
        },
        outOfStock () {
            let outOfStock = false
            if (!(this.userToken != '' && this.balance > 0 && this.qtyInCart < this.balance)) {
                outOfStock = true
            }
            return outOfStock
        }
    },
    methods: {
        delFromWishlist () {
            this.$store.dispatch('addDelWishlistItem', {good_id: this.id, authToken: this.userToken, action: 'del'})
        },
        addToCart () {
            if (!this.outOfStock) {
                this.loading = true
                setTimeout(() => {
                    this.$store.dispatch('addDelCartItem', 
                    {
                        good_id: this.id,
                        authToken: this.userToken,
                        quantity: 1,
                        action: 'add'
                    })
                    this.loading = false
                }, 50)
            }
        },
    }
}
</script>
<template>
    <td class="product">
        <img v-if="mainImage" class="product-thumb" :src="mainImage" alt="{{ name }}">
        <img v-else class="product-thumb" :src="PreviewImage" alt="{{ name }}">
        <div class="product-info">
            <h3 class="product-name">
                <router-link :to="{ name: 'good', params: { good_id: id}}">{{ name }}</router-link>
            </h3>
            <span class="product-price">{{ price }}</span>
        </div>
    </td>
    <td class="stock">
        <span v-if="blc > 0" class="in-stock">Остаток: {{ blc }} шт.</span>
        <span v-else class="out-of-stock">Нет в наличии</span>
    </td>
    <td>
        <div class="quantity">
            <input 
                v-model="qty"
                title="Qty" 
                class="input-text qty text" 
                size="4"
                @keyup.enter="setCartItemQty"
                @blur="setCartItemQty"
                @keypress="isNumber($event)"
            >
        </div>
    </td>
    <td class="product-subtotal">
        <span class="amount">{{ amount }}</span>
    </td>
    <td class="product-remove">
        <a @click="delFromCart" class="remove cursor-pointer"><i class="fa fa-close"></i></a>
    </td>
</template>

<script>
import PreviewImage from "@/assets/images/products/preview.png"
import {backendPath} from "@/main.js"
export default {
    name: 'CartItemComponent',
    props: ['goodInfo', 'quantity', 'balance'],
    data () {
        return {
            PreviewImage,
            id: this.goodInfo.id,
            name: this.goodInfo.full_name,
            art: this.goodInfo.art,
            price: this.goodInfo.price,
            blc: Math.floor(this.balance),
            qty: Math.floor(this.quantity),
            amount: (this.goodInfo.price * this.quantity).toFixed(2)
        }
    },
    computed: {
        userToken () {
            return this.$store.getters.user_token
        },
        mainImage () {
            let path = ''
            if (this.goodInfo.images.length > 0) {
                path = backendPath + this.goodInfo.images[0].image.url
            }
            return path
        }
    },
    methods: {
        delFromCart () {
            this.$store.dispatch('addDelCartItem', 
                {   good_id: this.id,
                    authToken: this.userToken,
                    action: 'del',
                    quantity: this.qty
                }
            )
        },
        setCartItemQty () {
            if (Number(this.qty) > 0) {
                this.$store.dispatch(
                    'setCartItemQty',
                    {
                        good_id: this.id,
                        authToken: this.userToken,
                        quantity:this.qty,
                    }
                )
            }
        },
        isNumber (evt) {
            const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
            const keyPressed = evt.key;
            
            if (!keysAllowed.includes(keyPressed)) {
                evt.preventDefault()
            }
        }
    }
}
</script>
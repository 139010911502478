<template>
  <div class="home">
    <HeaderComponent />
    <div class="main-container">
      <div class="container">
        <DefaultBreadCrumbs pageName="О компании"/>
        <div class="row justify-content-center">
          <div class="col-12 col-md-11 main-content">
            <div class="blog-single">
              <div class="post-item">
                <div class="post-info as-company-info">
                  <h3 class="post-title">О компании</h3>
                  <div class="blog-content">
                    <p>
                      Торговый дом «Сидроград» основывался как специализированная компания,
                        которая занимается продажей исключительно сидра. 
                        Сидр набирает популярность в России, и мы делаем все, 
                        чтобы жители нашего региона в числе первых могли наслаждаться 
                        лучшими представителями этого напитка. Мы предлагаем поставку 
                        разливных и бутылочных сидров российского и импортного производства. 
                        А наш успешный многолетний опыт продаж позволил сформировать 
                        сбалансированный ассортимент от традиционных сидров до премиальных.
                    </p>
                    <p>
                      Зарекомендовав себя как надёжного поставщика сидров, по многочисленным 
                      просьбам наших партнёров, мы ввели в наш ассортимент пиво самых 
                      популярных мировых производителей, а также безалкогольные напитки.
                    </p>
                    <p>
                      Мы тщательно отбираем наших поставщиков, знакомимся с производством и 
                      предлагаем вам только качественный, натуральный продукт!
                    </p>
                    <p>
                      Наш склад с полной линейкой ассортимента находится в г. Белгород. 
                      А это значит, что мы быстро и бесплатно доставим ваш заказ по 
                      Белгородской области и в соседние регионы.
                    </p>
                    <p>
                      Также, мы всегда готовы приехать к вам с презентацией наших напитков, 
                      рассказать об их особенностях и вкусовых различиях.
                    </p>
                  </div>
                </div>
                <div class="blog-single-footer">
                  <h4 class="post-title">БУДЕМ РАДЫ ВИДЕТЬ ВАС В ЧИСЛЕ НАШИХ ПАРТНЕРОВ!</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> 
    <div class="margin-bottom-40">
      <div class="container">
        <div class="row as-features">
          <div class="as-post-info margin-bottom-40">
            <h3 class="post-title" style="text-align: center;">Наши преимущества</h3>
          </div>
          <div class="col-sm-4">
            <div class="box-icon style3 margin-bottom-50">
              <div class="icon">
                <span><i class="fa fa-truck" aria-hidden="true"></i></span>
              </div>
              <div class="box-content">
                <h3 class="title">БЕСПЛАТНАЯ ДОСТАВКА</h3>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="box-icon style3 margin-bottom-50">
              <div class="icon">
                <span><i class="fa fa-percent" aria-hidden="true"></i></span>
              </div>
              <div class="box-content">
                <h3 class="title">ЛУЧШИЕ ЦЕНЫ</h3>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="box-icon style3 margin-bottom-50">
              <div class="icon">
                <span><i class="fa fa-home" aria-hidden="true"></i></span>
              </div>
              <div class="box-content">
                <h3 class="title">СОБСТВЕННЫЙ СКЛАД</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FooterComponent />
  </div>
</template>

<script>
import HeaderComponent from '@/components/Header/Header.vue'
import FooterComponent from '@/components/Footer/Footer.vue'
import DefaultBreadCrumbs from '@/components/DefaultBreadCrumbs.vue'

export default {
  name: 'AboutView',
  components: {
    HeaderComponent,
    DefaultBreadCrumbs,
    FooterComponent,
  },
  watch: {
      '$route.query': {
          immediate: true,
          handler() {
            document.title = 'О Компании'
          },
      },
  },
}
</script>
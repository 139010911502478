import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { globalCookiesConfig } from "vue3-cookies"
import { fullscreenImagePlugin } from 'vue-3-fullscreen-image-directive-plugin'
import 'vue-3-fullscreen-image-directive-plugin/style.css'
import Notifications from '@kyvg/vue3-notification'
import { SmartCaptchaPlugin } from 'vue3-smart-captcha'
import naive from "naive-ui"

export const backendPath = 'https://back.sidrograd.ru'
export const projectID = 'cfae83e0-3512-41ee-9456-7935a3a83f80'
export const yandexCaptcha = 'ysc1_f9a1TW5q9e27MXXlac9ezUFHJUJ1eSPcwtupqmdG64eba11b'

globalCookiesConfig({
    expireTimes: "7d",
  });

createApp(App).use(SmartCaptchaPlugin).use(Notifications).use(fullscreenImagePlugin).use(naive).use(store).use(router).mount('#app')

<template>
    <div class="home">
        <HeaderComponent />
        <div class="main-container no-sidebar padding-bottom-30">
            <OrderListItemContentComponent />
        </div>
        <FooterComponent />
    </div>
</template>

<script>
import HeaderComponent from '@/components/Header/Header.vue'
import FooterComponent from '@/components/Footer/Footer.vue'
import OrderListItemContentComponent from '@/components/User/Order/OrderListItemContentComponent.vue'

export default {
    name: "OrderListItemView",
    components: {
        HeaderComponent,
        FooterComponent,
        OrderListItemContentComponent,
    },
}
</script>